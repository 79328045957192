<template>
  <sm-master-layout>
    <div id="container">
      <div class="sm-scan-qr">
        <div class="sm-scan-qr-label">
          <span>QR-Code scannen, um zur Artikelseite zu gelangen</span>
        </div>

        <div v-if="activatedCamera" class="sm-scan-qr-window">
          <qr-stream @decode="onDecode" class="mb" @init="onInit">
            <div style="color: red;" class="frame"></div>
          </qr-stream>
        </div>

        <div
          v-else
          @click="onToggleCamera"
          class="sm-scan-qr-window is-deactivated">
          <div class="sm-scan-qr-window-content">
            <img src="img/camera.png" alt="camera icon">
            <p>Tippen um Kamera zu aktivieren</p>
          </div>
        </div>

        <div class="sm-scan-qr-bottom">
          <div class="sm-scan-qr-btn-toggle-camera">
            <ion-button @click="onToggleCamera" expand="block" fill="outline" color="secondary">
              {{ activatedCamera ? 'KAMERA DEAKTIVIEREN' : 'KAMERA AKTIVIEREN' }}
            </ion-button>
          </div>

          <div class="sm-scan-qr-or-div">
            <span>oder</span>
          </div>

          <div class="sm-scan-qr-btn-checkout">
            <ion-button expand="block" color="primary" @click="() => router.push({name: 'addresses'})">ZUR KASSE</ion-button>
          </div>

          <div class="sm-scan-qr-btn-shopping-cart">
            <ion-button expand="block" color="secondary" @click="() => router.push({name: 'shopping-cart'})">ZUM WARENKORB</ion-button>
          </div>
        </div>
      </div>
    </div>
  </sm-master-layout>
</template>

<script lang="javascript">
import {IonButton} from '@ionic/vue';
import {defineComponent, reactive, toRefs} from 'vue';
import {mapMutations, mapGetters} from 'vuex';
import {QrStream} from 'vue3-qr-reader';
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import CommonMixin from '@/mixins/common.mixin';
import SmMasterLayout from "@/components/layout/SmMasterLayout";
import ApiService from '@/services/api.service';
import ProductService from '@/services/product.service';
import {PRODUCT_DETAIL_API_PATH} from "@/constants/api.constant";
import {useRouter} from "vue-router";
import {alertCircle} from "ionicons/icons";
import {B2B_CUSTOM_PRODUCT_NUMBER_API} from "@/constants/api.constant";

export default defineComponent({
  name: 'Home',

  mixins: [ScreenLoadingMixin, CommonMixin],

  components: {
    QrStream,
    SmMasterLayout,
    IonButton
  },

  data() {
    return {
      activatedCamera: false,
      camera: 'auto',
      showScanConfirmation: false
    }
  },

  setup() {
    const router = useRouter();

    const state = reactive({
      productNo: null
    })

    async function onDecode(data) {
      state.productNo = data
    }

    return {
      ...toRefs(state),
      onDecode,
      router,
      alertCircle
    }
  },

  watch: {
    productNo(value) {
      if (!value) {
        return;
      }

      let [productId] = value.split('|');
      this.loadProduct(productId);
      this.productNo = null;
    }
  },

  ionViewWillEnter() {
    this.setHeaderTitle('Artikel Scannen');
  },

  mounted() {
    this.clearScannedProduct();
  },

  computed: {
    ...mapGetters('product', ['getScannedProduct', 'isFreeProduct']),
    ...mapGetters('auth', ['getCustomer', 'isLoggedIn'])
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle']),
    ...mapMutations('product', ['setScannedProduct', 'clearScannedProduct']),


    objIsEmpty(obj) {
      return obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;
    },

    async loadProduct(id) {
      this.pauseCamera();
      await this.timeout(500);
      this.unpause();

      const loading = await this.presentScreenLoading();
      await this.loadProductFromStorage(id);

      if (!this.getScannedProduct || this.objIsEmpty(this.getScannedProduct)) {
        await this.loadProductFromApi(id);
      }

      if (!this.getScannedProduct || this.objIsEmpty(this.getScannedProduct)) {
        this.afterNoProductLoaded(loading);

        return;
      }
      this.afterProductLoaded(loading);
    },

    async loadProductFromStorage(id) {
      try {
        let product = await ProductService.getProductFromIdb(id);

        const customerProductNo = await this.getCustomProductNumber(id);
        if (product) {
          product.id = id;

          if (customerProductNo) {
            product.customProductNo = customerProductNo;
          }
          this.setScannedProduct(product);
          return;
        }
        this.setScannedProduct(null);
      } catch (e) {
        this.setScannedProduct(null);
        console.log(e);
      }
    },

    async loadProductFromApi(id) {
      try {
        const response = await ApiService.get(PRODUCT_DETAIL_API_PATH(id));
        let product = response.data;

        product = product[id];
        product.id = id;
        await ProductService.setProductToIdb(product);
        this.setScannedProduct(product);
      } catch (e) {
        this.setScannedProduct(null);
        console.log(e);
      }
    },

    onToggleCamera() {
      this.activatedCamera = !this.activatedCamera;
    },

    afterProductLoaded(loading) {
      this.dismissScreenLoading(loading);
      this.activatedCamera = false;

      if (this.isFreeProduct) {
        this.$router.push({name: 'scanned-product-free'});

        return;
      }
      this.$router.push({name: 'scanned-product'});
    },

    afterNoProductLoaded(loading) {
      this.dismissScreenLoading(loading);
      this.activatedCamera = false;
      this.$router.push({name: 'scanned-product-not-found'});
    },

    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "ERROR: you need to grant camera access permission"
        } else if (error.name === 'NotFoundError') {
          this.error = "ERROR: no camera on this device"
        } else if (error.name === 'NotSupportedError') {
          this.error = "ERROR: secure context required (HTTPS, localhost)"
        } else if (error.name === 'NotReadableError') {
          this.error = "ERROR: is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "ERROR: installed cameras are not suitable"
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "ERROR: Stream API is not supported in this browser"
        } else if (error.name === 'InsecureContextError') {
          this.error = 'ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.';
        } else {
          this.error = `ERROR: Camera error (${error.name})`;
        }
      } finally {
        this.showScanConfirmation = this.camera === "auto"
      }
    },

    unpause() {
      this.camera = 'auto'
    },

    pauseCamera() {
      this.camera = 'off'
    },

    timeout (ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },

    async getCustomProductNumber(productId) {
      if (this.isLoggedIn) {
        try {
          const getCustomProductNoResponse = await ApiService.post(B2B_CUSTOM_PRODUCT_NUMBER_API, {
            productIds: [productId]
          });
          if (getCustomProductNoResponse.status === 200) {
             const data = getCustomProductNoResponse.data.data;
             console.log(data);
             console.log(data[productId]);
             return data[productId];
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
  }
})
</script>

<style scoped>
  .sm-scan-qr-window {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    overflow: hidden;
    margin: auto;
    width: 85vw;
    height: 85vw;
    cursor: pointer;
  }

  .sm-scan-qr-window.is-deactivated {
    border: 1px solid #000000;
  }

  .sm-scan-qr-window-content {
    width: 60%;
    text-align: center;
  }

  .sm-scan-qr-label {
    text-align: left;
    padding: 1.5rem 0 1rem;
  }

  .sm-scan-qr-btn-toggle-camera {
    padding: 0.5rem 0;
  }

  .sm-scan-qr-or-div {
    text-align: center;
  }

  .sm-scan-qr-btn-checkout {
    padding: 0.5rem 0 0.7rem;
  }

  .sm-scan-qr-btn-shopping-cart {
    padding-bottom: 1rem;
  }
</style>
